import React from 'react'

const WebinarCard=({companyName,number,webinar})=> {
    return (
        <div>
                <div
        className="backGrond"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="gridForWebinar container">
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="impossibleNumber"> {number} </div>
              <div className="blockHeght"></div>
            </div>
            <div>
              <h1 style={{ color: "#4d4d4d", fontFamily: "serif" }}> {companyName} </h1>
           
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={webinar} alt="Zendesk" className="img-fluid widthImage" />
          </div>
        </div>
      </div>
        </div>
    )
}

export default WebinarCard
