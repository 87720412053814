import React from "react";
import pic from "../images/location.png";

const ContactAddress = ({
  title,
  subtitle,
  subtitle1,
  subtitle2,
  subtitle3,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            src={pic}
            alt="location"
            style={{ width: "50px", height: "50px", marginBottom: "30px" }}
          />
        </div>
        <h3>{title}</h3>
        <p>
          {subtitle}
          <br />
          {subtitle1}
        </p>
        <p>{subtitle2}</p>
        <p>{subtitle3}</p>
      </div>
    </div>
  );
};
export default ContactAddress;
