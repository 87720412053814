import React, { useEffect } from "react";
import ContactAddress from "../components/ContactAddress";

import MapG from "../images/gmap.png";
import Footer from "../components/Footer";
import { Button } from "antd";

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="contactContainer">
        <h1>We want to hear from you</h1>
        <h5>
          Tell us little bit about yourself & your question,
          <br /> and someone will be in touch.{" "}
        </h5>
      </div>
      <div className="contactDetails">
        <div>
          <ContactAddress
            title="Office in California"
            subtitle="2468 S Bascom Ave, #4"
            subtitle1="Campbell, CA 95008"
            subtitle2="Phone Number: 630-326-8036"
            subtitle3="Mail: info@venaliciumdigital.com"
          />
        </div>
        <div>
          <ContactAddress
            title="Office in Chicago"
            subtitle="4075, Meadow View Drive,"
            subtitle1="St. Charles , IL 60175"
            subtitle2="Phone Number: 630-326-8036"
            subtitle3="Mail: info@venaliciumdigital.com"
          />
        </div>
        <div>
          <ContactAddress
            title="Office in India"
            subtitle="RMV-II stage, Dollars Colony,"
            subtitle1="Opp 2nd Cross,Bangalore 560094"
            subtitle2="Phone Number: +91-9740436831"
            subtitle3="Mail: info@venaliciumdigital.com"
          />
        </div>
      </div>
      <div className="imagebehindformKV"> </div>

      {/* grid for contact form */}
      <div className="formMainGridContactKV">
        {/* column 1 starts */}
        <div className="formcolumnOfGrid">
          <h4 className="formheadingContactKV">
            LET'S DISCUSS YOUR PROJECTS NOW
          </h4>
          <h1 className="formheadingh1ContactKV">Get in touch with us</h1>
          <div className="formCenter">
            <div>
              <form
                action="https://formsubmit.co/bilistorage01@gmail.com"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_next"
                  value="https://www.biliweb.com/"
                />
                <input
                  placeholder="Your E-mail"
                  type="email"
                  name="email"
                  className="inputofFormContact"
                />

                <input
                  placeholder="Your Mobile"
                  type="text"
                  name="mobile"
                  className="inputofFormContact"
                />
                <textarea
                  name="textareaContact"
                  className="textareaContact"
                  rows="4"
                >
                  Message...
                </textarea>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    style={{ width: "250px" }}
                    size={"large"}
                  >
                    SEND MESSAGE
                  </Button>
                  <input type="hidden" name="_captcha" value="false" />
                  <input
                    type="hidden"
                    name="_cc"
                    value="venaliciumb2b@gmail.com"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* 2nd column */}
        <div className="mapColumnOfGrid">
          <a
            href="https://goo.gl/maps/1U1FXPMDBy9QW3Wr6"
            style={{ cursor: "pointer" }}
          >
            <img src={MapG} alt="contact b2b" className="officeMapContact" />
          </a>
        </div>
      </div>
      {/* LAST DIV STARTS HERE */}

      <Footer />
    </div>
  );
};
export default Contact;
