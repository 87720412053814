import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typical from "react-typical";
import Footer from "../components/Footer";
import Laptop from "../static/images/lapytop.svg";
import SelectSvg from "../static/images/selectobjective.svg";
import Audience from "../static/images/audience.svg";
import Webinar from "../static/images/swebinar.svg";
import zen from "../images/zenChina.png";
import Build from "../static/images/buildpresent.svg";
import Rehearse from "../static/images/reherse.svg";
import Sign from "../static/images/sign.svg";
import Tab from "../static/images/tab.svg";
import Promote from "../static/images/promotewebinar.svg";
import { getBlogs } from "../functions/blog";
import parse from "html-react-parser";
import { Button } from "antd";

function Home() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    loadAllBlogs();
  }, []);

  const loadAllBlogs = () => {
    getBlogs().then((res) => {
      setBlogs(res.data);
      console.log(res);
    });
  };

  return (
    <div>
      <div className="backgroundimgas">
        <h1 className="title">V3 Webinar</h1>
        <h5 className="subtitle">
          A Virtual Rendezvous For Event Professionals
          <br />
          <div className="heading " style={{ color: "#006bc9" }}>
            We Help{" "}
            <span className="typicalword">
              <Typical
                loop={Infinity}
                wrapper="B"
                steps={[
                  "Marketers",
                  1000,
                  "Startups",
                  1000,
                  "Businesses",
                  1000,
                  "Entrepreneurs",
                  1000,
                ]}
              />
            </span>
            <br />
            Get 10X Results
            <br />
            From Online{" "}
            <span className="typicalword">
              <Typical
                loop={Infinity}
                wrapper="B"
                steps={[
                  "Webinars",
                  1000,
                  "Events",
                  1000,
                  "Virtual Summits",
                  1000,
                  "Webcasts",
                  1000,
                ]}
              />
            </span>
          </div>
        </h5>

        <div className="text-center  pb-5"></div>
      </div>

      <div className="contactusafter">
        <div className="navcomponentfor">
          <div className="allcomp container">
            <span className="first">
              <a className="achorStyle" href="#first">
                {" "}
                <div>First thing first</div>
              </a>
            </span>
            <span className="first">
              <a className="achorStyle" href="#why">
                {" "}
                <div>why v3 webinar</div>
              </a>
            </span>
            <span className="first">
              <a className="achorStyle" href="#how">
                <div>how to create a webinar</div>
              </a>
            </span>
          </div>
          <div></div>
        </div>
      </div>

      <div
        className="whatiswebinar container"
        id="fortop"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
        id="first"
      >
        <h5 className="firstthing">First thing first</h5>
        <h3 className="h2webinarwhatis">What Is a Webinar?</h3>
        <p className="paraforwhatis">
          A webinar is an online seminar that turns a presentation into a
          real-time conversation from anywhere in the world. Webinars allow
          large groups of participants to engage in online discussions or
          training events and share audio, documents or slides – even when
          they’re not in the same place as the meeting host or in the same room
          where the event or presentation is taking place.
        </p>
      </div>
      <div
        className="imgsource container "
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <img className="imgsrcimages" src={Laptop} alt="alt" />
      </div>
      <div
        className="detailsgrid container"
        data-aos="zoom-in-right"
        data-aos-duration="1500"
      >
        <div className="firstside container">
          <h3 className="divforh2grid">Engaging Online Discussions</h3>
          <p className="parforfirstside">
            Used effectively, webinars help you better understand your audience,
            their pain points and the topics most relevant to them.
          </p>
        </div>
        <div className="secondside">
          <div
            className="firstside container"
            data-aos="zoom-in-left"
            data-aos-duration="1500"
          >
            <h3 className="divforh2grid">Actionable Content</h3>
            <p className="parforfirstside">
              Effective webinars often pick up where traditional slide
              presentations leave off, adding screen sharing, presenter video
              and audio and downloadable materials.
            </p>
          </div>
        </div>
      </div>

      <div
        className="whatiswebinar container"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="2000"
      >
        <h5 className="firstthing">USING WEBINARS</h5>
        <h3 className="h2webinarwhatis">How Do Effective Webinars Work?</h3>
        <p className="paraforwhatis">
          An effective and engaging webinar starts with three roles: an
          organizer, at least one presenter and attendees. A few quick steps can
          help you reach your target audience and spread your message to people
          online.
        </p>

        <div className="cardswhatis">
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={SelectSvg} alt="alt" />
            <h5 className="h2webinarwhatis">Set a Clear Objective</h5>
            <p className="paraforwhatisfor">
              You’ll be more successful if you have a clear goal in mind. What
              are you trying to accomplish?
            </p>
          </div>
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={Audience} alt="alt" />
            <h5 className="h2webinarwhatis">Understand Your Audience</h5>
            <p className="paraforwhatisfor">
              What are your attendees’ pain points? What topics might resonate
              with them? Use those insights to create a compelling webinar
              title.
            </p>
          </div>
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={Webinar} alt="alt" />
            <h5 className="h2webinarwhatis">Schedule Your Webinar</h5>
            <p className="paraforwhatisfor">
              Research shows 10am on a Tuesday or Wednesday are the best days
              and times to host a webinar.
            </p>
          </div>
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={Promote} alt="alt" />
            <h5 className="h2webinarwhatis">Promote Your Webinar</h5>
            <p className="paraforwhatisfor">
              Use email, social channels and your own network to get the word
              out.
            </p>
          </div>
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={Build} alt="alt" />
            <h5 className="h2webinarwhatis">Build Out Your Presentation</h5>
            <p className="paraforwhatisfor">
              “How-to” content tends to perform best. Give your audience
              bite-size learnings they can apply.
            </p>
          </div>
          <div
            className="cardstyle container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img className="fa-users" src={Rehearse} alt="alt" />
            <h5 className="h2webinarwhatis">Rehearse!</h5>
            <p className="paraforwhatisfor">
              Make sure you practice your delivery as well as test your
              technical setup before the big day.
            </p>
          </div>
        </div>
      </div>

      <div className="v3blogmain">
        <div className="container">
          <h3
            style={{
              color: "#fff",
              textAlign: "center",
            }}
          >
            Some Webinar Blogs
          </h3>
          <div className="threeblogDiv">
            {blogs.slice(0, 3).map((b, i) => (
              <div>
                <div className="v3card2">
                  <img src={b.images[0].url} className="v3image" alt="..." />
                  <div className="v3blogbody">
                    <h5 className="v3blog-tilte">{b.title} </h5>
                    <p className="v3blogexcerpt">
                      {parse(`${b.description.substring(0, 250)}.....`)}
                    </p>
                    <Link to={`/blog/${b.slug}`} className="read">
                      Read More <span class="spanscript"> </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr className="style14" />
      <div
        className="blue"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div
          className="contentcontact"
          data-aos="zoom-in-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h1 className="leftcar">
            {" "}
            <span className="spandesk">Webinar</span> <br />
            Promotion Like No Where Else.
            <br />
            Contact Us Today!
          </h1>
        </div>
        <div
          class="white"
          data-aos="zoom-in-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <form
            action="https://formsubmit.co/info@v3finmedia.online"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value="https://www.v3webinar.com/"
            />
            <div class="butted">
              <i class="fas fa-id-badge"></i>
              <input
                required
                type="text"
                class="txtab"
                name="user_name"
                id="name"
                placeholder="Name"
              />
            </div>
            <div class="butted">
              <i class="fas fa-envelope"></i>
              <input
                required
                type="email"
                id="mail"
                class="txtab"
                name="email"
                placeholder="Email Address"
              />
            </div>

            <div class="butted">
              <i class="fas fa-pen-square"></i>
              <input
                type="text"
                class="txtab"
                name="company-name"
                id="msg"
                placeholder="Company Name"
              />
            </div>

            <div class="butted">
              <button type="submit" class=" butana button btn">
                Submit
              </button>
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_cc" value="venaliciumb2b@gmail.com" />
            </div>
          </form>
        </div>
      </div>

      <hr className="style14" />
      <div
        className="whatiswebinar container"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="2000"
        id="why"
      >
        <h5 className="firstthing">WHY v3WEBINAR</h5>
        <h3 className="h2webinarwhatis">
          Advantages and Uses of a Webinar Software
        </h3>
        <p className="paraforwhatis">
          Increased participation is just the beginning. Check out these webinar
          benefits for your business.
        </p>

        <div className="cardfor2column container">
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Train Employees & Customers</h5>
            </div>
            <p className="paraforwhatisforp">
              V3Webinar lets you interact effectively with a widely dispersed
              workforce in real time. Provide pre-recorded or live workshops to
              audiences of up to 2,000 people.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Build Rapport</h5>
            </div>
            <p className="paraforwhatisforp">
              Whether you use it to inform customers or educate employees,
              V3Webinar helps you engage authentically. This leads to improved
              customer satisfaction and increased employee engagement.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Generate Leads & Awareness</h5>
            </div>
            <p className="paraforwhatisforp">
              Use email, social channels and your own network to get the word
              out.V3Webinar helps you better know your customers. Harness data
              and questions to develop a more accurate customer profile. The
              result: better customer targeting.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Improve Sales</h5>
            </div>
            <p className="paraforwhatisforp">
              Focus on high-quality leads for your sales team. With V3Webinar,
              you spend more time with the leads most likely to pay off.
            </p>
          </div>
        </div>
      </div>

      <hr className="style14" />
      <div
        className="whatiswebinar container"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="2000"
      >
        <h5 className="firstthing">USEFUL TIPS</h5>
        <h3 className="h2webinarwhatis">
          Tips for Creating Effective Webinars
        </h3>
        <div className="cardfor2column container">
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">60 Minutes or Less</h5>
            </div>
            <p className="paraforwhatisforp">
              Keep your webinar to under an hour; if the webinar is too long,
              participation may drop.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Promote Participation</h5>
            </div>
            <p className="paraforwhatisforp">
              Use polls, ask thought-provoking questions and provide actionable
              content. The advantage of webinars over standard webcasts is that
              webinars encourage discussion, so be sure to take steps to keep
              your attendees thinking, asking questions and commenting.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Quality is Key</h5>
            </div>
            <p className="paraforwhatisforp">
              Use a high-quality microphone to ensure that every attendee is
              clearly picking up what you are sharing.
            </p>
          </div>
          <div
            className="pp1 container"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="tick">
              <i className="fa fa-check" aria-hidden="true"></i>
              <h5 className="h3webinarwhatis">Don’t Rush</h5>
            </div>
            <p className="paraforwhatisforp">
              When participants feel that something is being hurried, they
              unconsciously try to help move it along, which means participation
              dries up. Instead, plan wisely so that you’ll have enough time to
              get through what you need to, while still leaving space for Q&A.
            </p>
          </div>
        </div>
      </div>
      <hr className="style14" />

      <div
        className="whatiswebinar container"
        id="fortop2"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="2000"
        id="how"
      >
        <h5 className="firstthing">GETTING STARTED</h5>
        <h3 className="h2webinarwhatis">How to Create a Webinar</h3>
        <p className="paraforwhatis">
          Creating a webinar with V3Webinar takes just a few steps.
        </p>
      </div>
      <div className="container ">
        <div className="row container">
          <div className="h1aasdj col-md">
            <a href="/contactus" className="hrefforc">
              <div className="h1aasdjas">
                <img className="sign" src={Sign} alt="alt" />
                <h5 style={{ color: "black" }}>1. Grow With V3Webinar</h5>
              </div>
              <h5>Want to know More Cobtact Us Today!</h5>
            </a>
            <hr className="style14" />

            <div className="h1aasdjas">
              <img className="sign" src={Webinar} alt="alt" />
              <h5 style={{ color: "black" }}>2. Schedule Your Webinar</h5>

              <h5 style={{ color: "#1F85DE" }}>
                Click Schedule a Webinar and create a title and description for
                your webinar.
              </h5>
            </div>
          </div>
          <div
            className="imagedas col-md"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            <img src={Tab} alt="alt" />
          </div>
        </div>
      </div>

      <hr className="style14" />

      <div className="formmaachuda container" id="formsc">
        <h1 className="scweb">Schedule Your Webinar</h1>
        <form
          action="https://formsubmit.co/info@v3finmedia.online"
          method="POST"
        >
          <input
            type="hidden"
            name="_next"
            value="https://www.v3webinar.com/"
          />
          <div className="name">
            <input
              type="text"
              className="firstname"
              name="first-name"
              placeholder="First Name"
              required
            />
            <input
              type="text"
              className="firstname"
              name="last-name"
              placeholder="Last Name"
              required
            />
          </div>
          <div className="sname">
            <input
              type="text"
              className="companyname"
              name="company-name"
              placeholder="Company Name"
              required
            />
          </div>
          <div className="sname">
            <input
              type="text"
              className="Geography"
              name="geography-name"
              placeholder="Geography"
              required
            />
          </div>
          <div className="name">
            <input
              type="email"
              className="firstname"
              name="email"
              placeholder="Email"
              required
            />
            <input
              type="phonenumber"
              className="firstname"
              name="Number"
              placeholder="Contact Number"
              required
            />
          </div>
          <textarea
            name="specific-criteria"
            className="textareafor"
            placeholder="   Specific-Criteria"
          ></textarea>
          <div className="butcheck">
            <input
              required
              id="kuchbhi"
              type="checkbox"
              name="_captcha"
              value="true"
            />
            <label className="label" for="kuchbhi">
              I authorise V3Webinar and partners to contact through email and
              phone{" "}
            </label>
          </div>
          <div className="marginforbutton">
            <button type="submit" className="button btn  ">
              {" "}
              Submit
            </button>
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_cc" value="venaliciumb2b@gmail.com" />
          </div>
        </form>
      </div>

      <div className="blogscontain">
        <div className="cards">
          <Link to="/webinars">
            <div className="wrappered baby">
              <div className="cardss">
                <h1 className="textaling">
                  <span className="enclosed">Recent</span>Webinars
                </h1>
              </div>
            </div>
          </Link>
          <Link to="/blogs">
            <div className="wrappered baba">
              <div className="cardss">
                <h1 className="textaling">
                  <span className="enclosed">Recent</span>Blogs
                </h1>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
