import { Button } from "antd";
import React, { useEffect, useState } from "react";

import { getBlogs } from "../../functions/blog";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import webinar from "../../images/banner.png";
import Footer from "../../components/Footer";
import WebinarCard from "../../components/cards/WebinarCard";
import zendesk from "../../images/banner.png";
import zoom from "../../images/zoom.png";
import zen from "../../images/zenChina.png";
import yealink from "../../images/yealink.png";
import tangent from "../../images/tangentworks.jpg";
import poly from "../../images/poly.png";
import oracle from "../../images/oracle.png";
import netapp from "../../images/netapp.png";
import linode from "../../images/herokudigital.65a65c84.gif";

import github from "../../images/github.png";
import eka from "../../images/eka.png";

const Webinar = (props) => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    loadAllBlogs();
    window.scrollTo(0, 0);
  }, []);

  const loadAllBlogs = () => {
    getBlogs(2).then((res) => {
      setBlogs(res.data);
      console.log(res);
    });
  };

  return (
    <div>
      <div
        className="  mt-5 pb-5 pt-5"
        style={{
          backgroundColor: "#f2f2f2",
        }}
      >
        <h3
          style={{
            color: "#0093dc",
            textAlign: "center",
            fontSize: "50px",
            fontFamily: "serif",
          }}
        >
          Our Recent Webinars
        </h3>
        <div className="webinarList">
          <Link to="/">
            <img src={zen} className="img-fluid" alt="zendesk" />
          </Link>
        </div>
        {/* <div className="text-center mt-5">
          <Button
            href="/"
            type="primary"
            size={"large"}
          >
            Register Now!
          </Button>
        </div> */}
      </div>
      <div className="webinarList">
        <WebinarCard webinar={yealink} number="1" companyName="Yealink" />
        <WebinarCard webinar={zoom} number="2" companyName="Zoom" />
      </div>
      <div className="webinarList">
        <WebinarCard webinar={eka} number="3" companyName="EKA" />
        <WebinarCard webinar={github} number="4" companyName="GitHub" />
      </div>
      <div className="webinarList">
        <WebinarCard webinar={zendesk} number="5" companyName="Zendesk" />
        <WebinarCard webinar={linode} number="6" companyName="Linode" />
      </div>

      <div className="webinarList">
        <WebinarCard webinar={netapp} number="7" companyName="NetApp" />
        <WebinarCard webinar={oracle} number="8" companyName="Oracle" />
      </div>
      <div className="webinarList">
        <WebinarCard webinar={poly} number="9" companyName="Poly" />
        <WebinarCard
          webinar={tangent}
          number="10"
          companyName="Tangent Works"
        />
      </div>

      <div className="v3blogmain">
        <div className="container">
          <h3
            style={{
              color: "#fff",
              textAlign: "left",
              fontSize: "50px",
              fontFamily: "serif",
            }}
          >
            Our Recent Blogs
          </h3>
          <div className="threeblogDiv">
            {blogs.slice(0, 3).map((b, i) => (
              <div>
                <div className="v3card2">
                  <img src={b.images[0].url} className="v3image" alt="..." />
                  <div className="v3blogbody">
                    <h5 className="v3blog-tilte">{b.title} </h5>
                    <p className="v3blogexcerpt">
                      {parse(`${b.description.substring(0, 250)}.....`)}
                    </p>
                    <Link to={`/blog/${b.slug}`} className="read">
                      Read More <span class="spanscript"> </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Webinar;
