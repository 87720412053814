import React, { useEffect } from "react";
import banner from "../../images/banner.png";
import delia from "../../images/Delia.jpeg";
import avin from "../../images/Alvin.jpeg";
import { Button } from "antd";
import Footer from "../../components/Footer";
const Zendesk = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: "100px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={banner} alt="Zendesk" className="img-fluid" />
      </div>
      <div className="webinarGrid container">
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4>
            無法即時參與線上活動？
            <br />
            只需註冊即可獲取點播連結，
            <br />
            供您隨時觀看。
            <br />
          </h4>
          <div
            style={{
              width: "100px",
              height: "5px",
              backgroundColor: "#29abe2",
            }}
          ></div>
          <h3
            style={{
              marginTop: "50px",
              textAlign: "center",
              fontSize: "40px",
              fontFamily: "serif",
            }}
          >
            Speakers
          </h3>
          <div className="speakGrid">
            <div className="speakerCard">
              <img src={delia} alt="DeliaEe" className="speakerPic" />
              <div className="speakerName">
                <div className="speakerNamE">Delia Ee</div>
                <h5 className="speakerNamE">亞太區業務經理</h5>
                <h5 className="speakerNamE">Zendesk</h5>
              </div>
            </div>
            <div className="speakerCard">
              <img src={avin} alt="DeliaEe" className="speakerPic" />
              <div className="speakerName">
                <div className="speakerNamE">Alvin Ong</div>
                <h5 className="speakerNamE">亞太區業務經理</h5>
                <h5 className="speakerNamE">Zendesk</h5>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="formCard">
            <div className="formName">
              <div className="speakerNAmE">個人資料</div>
            </div>
            <div className="formContentweb">
              <form
                action="https://formsubmit.co/info@v3finmedia.online"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_next"
                  value="https://www.v3webinar.com/"
                />
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="">
                    <input
                      type="text"
                      className="inputFont"
                      name="first_name"
                      id="name"
                      placeholder="名 *"
                    />
                  </div>
                  <div className="">
                    <input
                      type="text"
                      required
                      id="mail"
                      className="inputFont"
                      name="last_name"
                      placeholder="姓 *"
                    />
                  </div>
                  <div className="">
                    <input
                      type="email"
                      required
                      id="mail"
                      className="inputFont"
                      name="email"
                      placeholder="公司電子郵件地址 *"
                    />
                  </div>
                  <div className="">
                    <input
                      type="text"
                      required
                      id="mail"
                      className="inputFont"
                      name="
                    job title"
                      placeholder="職稱 *"
                    />
                  </div>
                  <div className="">
                    <input
                      type="text"
                      required
                      id="mail"
                      className="inputFont"
                      name="
                    company name"
                      placeholder="公司名稱 *"
                    />
                  </div>
                  <div className="">
                    <input
                      type="text"
                      required
                      id="mail"
                      className="inputFont"
                      name="number"
                      placeholder="電話號碼 *"
                    />
                  </div>

                  <div className="select">
                    <select id="country" name="國家/地區 *">
                      <option value="國家/地區 *">國家/地區 *</option>
                      <option value="中國">中國</option>
                      <option value="香港">香港</option>
                      <option value="台灣">台灣</option>
                    </select>
                  </div>

                  <div className="select">
                    <select id="country" name="電腦硬體">
                      <option value="電腦硬體">電腦硬體</option>
                      <option value="航天與國防">航天與國防</option>
                      <option value="汽車">汽車</option>
                      <option value="化學和金屬與礦產業">
                        化學和金屬與礦產業
                      </option>
                      <option value="電腦硬體">電腦硬體</option>
                      <option value="電腦軟體與服務">電腦軟體與服務</option>
                      <option value="消費產品">消費產品</option>
                      <option value="消費服務">消費服務</option>
                      <option value="企業服務">企業服務</option>
                      <option value="教育">教育</option>
                      <option value="能源與環境">能源與環境</option>
                      <option value="金融服務">金融服務</option>
                      <option value="政府與非營利">政府與非營利</option>
                      <option value="政府與非營利">醫療保健設備與服務</option>
                      <option value="工業製造與服務">工業製造與服務</option>
                      <option value="媒體與娛樂">媒體與娛樂</option>
                      <option value="不動產服務">不動產服務</option>
                      <option value="零售和批發與分銷">零售和批發與分銷</option>
                      <option value="電信">電信</option>
                      <option value="交通">交通</option>
                    </select>
                  </div>

                  <div className="select">
                    <select id="country" name="員工人數 *">
                      <option value="員工人數">員工人數 *</option>
                      <option value="1 - 9">1 - 9</option>
                      <option value="10 -49">10 -49</option>
                      <option value="50 - 99">50 - 99</option>
                      <option value="100 - 249">100 - 249</option>
                      <option value="250 - 499">250 - 499</option>
                      <option value="500 - 999">500 - 999</option>
                      <option value="1000 - 4999">1000 - 4999</option>
                      <option value="5000+">5000+</option>
                    </select>
                  </div>

                  <div className="select">
                    <select id="country" name="您目前是否為 Zendesk 客戶？ *">
                      <option value="您目前是否為 Zendesk 客戶？ ">
                        您目前是否為 Zendesk 客戶？ *
                      </option>
                      <option value="是">是</option>
                      <option value="不是">不是</option>
                      <option value="我目前正在 30 天的試用期">
                        我目前正在 30 天的試用期
                      </option>
                      <option value="其它">其它</option>
                    </select>
                  </div>
                  <div>
                    <input
                      required
                      id="kuchnii"
                      type="checkbox"
                      name="_captcha"
                      value="true"
                    />
                    <label className="labelz" for="kuchnii">
                      是，請發送有關 B2B 產品與服務的通知。（您可隨時取消訂閱）
                    </label>
                  </div>
                  <div className="">
                    <input
                      required
                      id="kuchbhi"
                      type="checkbox"
                      name="_captcha"
                      value="true"
                    />
                    <label className="labelz" for="kuchbhi">
                      我同意 B2B 隱私政策 *
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        backgroundColor: "rgba(95, 158, 160, 0.356)",
                        width: "300px",
                      }}
                    >
                      <div>
                        <input
                          required
                          id="captcha"
                          type="checkbox"
                          name="_captcha"
                          value="true"
                        />
                      </div>
                      <div style={{ color: "white" }}>I'm not a robot</div>
                      <div>
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                          alt="alt"
                          className="asdfghz"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                      marginBottom: "40px",
                    }}
                  >
                    <Button
                      size={"large"}
                      type="submit"
                      style={{ width: "150px" }}
                    >
                      繼續
                    </Button>
                    <input type="hidden" name="_captcha" value="false" />
                    <input
                      type="hidden"
                      name="_cc"
                      value="venaliciumb2b@gmail.com"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Zendesk;
