import React,{useState} from "react";
import { Row, Col, Image, Input, Select, Radio, Button } from "antd";
import { COUNTRY } from "./COUNTRY";
import {
  cLevelDept,
  cLevelJob,
  customerService,
  dataCategories,
  Department,
  facilities,
  fieldServices,
  financeAccounting,
  Governance,
  HR,
  IT,
  legal,
  Manufacturing,
  Marketing,
  OperationsEngineering,
  Sales,
  Security,
  sharedServices,
} from "./Data";
const { Option } = Select;

export default function ServiceNow2() {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [value, setValue] = React.useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [jobLevel, setJobLevel] = useState("");
  const [jobFunction, setJobFunction] = useState("");
  const [department, setDepartment] = useState("");

  const handleJobChange = (e) => {
    console.log("TARGET.NAME====>>>", e.target.name);
    console.log("TARGET.VALUE======>>>>", e.target.value);
    setJobLevel(e.target.value);
  };

  const handleJobFunction = (e) => {
    setJobFunction(e.target.value);
  };

  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };


  return (
    <div>
      <div className="mt-5 container">
        <Row>
          <Col span={16}>
            <div className="pr-5" >
              <img
                src="https://info.servicenow.com/rs/089-ANS-673/images/Servicenow_logo_v1.png"
                height="30px"
              />
              <hr />
              <div>
                <p style={{ color: "293E40", fontSize: "18px" }}>WEBINAR</p>
                <h3 style={{ color: "253E41", fontSize: "26px" }}>
                  Know and Respond to Application Vulnerabilities
                </h3>
                <div
                  style={{
                    height: "155px",
                    width: "100%",
                    backgroundColor: "#f7f7f7",
                    borderTop: "solid 5px #119a9c",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ lineHeight: "50px" }}>
                      <div className="ml-2">
                        <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
                        Tuesday, April 21, 2021
                      </div>
                      <div className="ml-2">
                        <span style={{ fontWeight: "bold" }}>Time:</span>  11:30 am SGT/HKT 
                      </div>
                    </div>
                    {/* <Col span={4}> */}
                    <div>
                      <img
                        src="https://info.servicenow.com/rs/089-ANS-673/images/180623_Developers_9_3008%202520_400x400.jpg"
                        height="150px"
                        width="150px"
                        style={{ float: "right" }}
                      />
                    </div>
                  </div>
                  {/* </Col> */}
                </div>
              </div>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                Hosted by Julian Azaret of ServiceNow, this webinar will shine a
                light on your application vulnerabilities and what you can do to
                create a more secure development process.
                <br />
                Secure development practices are a part of the DevOps
                revolution. Security scanning should be incorporated into every
                aspect of the development pipeline—from code, to build, to
                runtime. But how can you aggregate all those security scanning
                results from different tools across all applications in
                development?
                <br />
                What you need is a single view of all application
                vulnerabilities, and to know what dev team is responsible for
                them—in real time and at scale. This can be a nearly impossible
                accomplishment at best, and if you fail to meet this need (and
                drive accountability), then you’ll likely end up with security
                flaws in production.
              </div>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                Save your spot today to learn how you can use the power of the
                NOW Platform to:
              </div>
              <div className="ml-5 mt-3">
                <ul>
                  <li>Identify and resolve application vulnerabilities</li>
                  <li>Focus on the larger context of your enterprise</li>
                  <li>Shift security left and fix faster</li>
                </ul>
              </div>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                Featured speakers:
                <div className="ml-2 mt-2">
                  <span style={{ fontWeight: "bold" }}>
                    Julian Azaret,<span> </span>{" "}
                  </span>
                  Outbound Product Manager, ServiceNow
                </div>
              </div>
            </div>
          </Col>
          <Col span={8} style={{ backgroundColor: "#f7f7f7" }}>
            <div
              style={{
                width: "20vw",
                float: "left",
              }}
              className="pl-3"
            >
              <div style={{ fontSize: "14px", marginTop: "20px" }}>
                Fill out the form below to register. * All form fields are
                required.
              </div>
              <form
                action="https://formsubmit.co/v3webinar@gmail.com"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_next"
                  value="https://www.v3webinar.com/thankyou"
                />
                <Input
                  placeholder="Email Address *"
                  className="mt-3"
                  type="email"
                  required
                  name="email"
                />
                <Input
                  placeholder="First Name *"
                  className="mt-3"
                  type="text"
                  name="First name"
                  required
                />
                <Input
                  placeholder="Last Name *"
                  className="mt-3"
                  type="text"
                  name="Last name"
                  required
                />
                <Input
                  placeholder="Business Phone *"
                  className="mt-3"
                  type="text"
                  name="Business Phone"
                  required
                />
                <Input
                  placeholder="Company *"
                  className="mt-3"
                  type="text"
                  name="company"
                  required
                />
                <Input
                  placeholder="Mobile Phone"
                  className="mt-3"
                  type="text"
                  name="Mobile Phone"
                  required
                />
                <select
                  style={{ width: "100%" }}
                  className="mt-3"
                  name="Country"
                >
                  {COUNTRY.map((c, i) => (
                    <option value={c.name} key={i}>
                      {c.code} - {c.name}
                    </option>
                  ))}
                </select>
                <Input
                  placeholder="State and Province *"
                  className="mt-3"
                  type="text"
                  name="State"
                />
               <select
                  style={{ width: "100%" }}
                  className="mt-3"
                  name="Job Level"
                  onChange={handleJobChange}
                >
                  {dataCategories.map((c, i) => (
                    <option value={c.categoryName} key={i}>
                      {c.categoryName}
                    </option>
                  ))}
                  )
                </select>

               <div className={jobLevel === "C-Level" ? 'hideit' : 'showit'}>
               {jobLevel === "C-Level" ? (
                  <select
                    style={{ width: "100%" }}
                    className="mt-3"
                    name="Department"
                    onChange={handleDepartment}
                  >
                    {cLevelDept.map((cl, i) => (
                      <option value={cl.jobFunction} key={i}>
                        {cl.clevelDept}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    style={{ width: "100%" }}
                    className="mt-3"
                    name="Department"
                    onChange={handleDepartment}
                  >
                    {Department.map((d, i) => (
                      <option value={d.dept} key={i}>
                        {d.dept}
                      </option>
                    ))}
                  </select>
                )}
               </div>


<div className={jobLevel === "C-Level" ? 'showit' : 'hideit'}>
<select
 style={{ width: "100%" }}
                    className="mt-3"
                    name="Department"
                    onChange={handleJobFunction}
>
{cLevelJob.map((c,i)=>(
  <option value={c.jobFunction} key={i}>
    {c.jobFunction}
  </option>
  ))}
</select>
</div>


                <div className={jobLevel === "C-Level" ? 'hideit' : 'showit'}>
                  {department === "Cloud Operations" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      <option value="Job Function" key="1">
                        Job Function *
                      </option>
                      <option value="Cloud Operations" key="2">
                        Cloud Operations
                      </option>
                    </select>
                  ) : department === "Customer Service/Support" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {customerService.map((c, i) => (
                        <option value={c.jobFunction} key={i}>
                          {c.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Facilities" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {facilities.map((f, i) => (
                        <option value={f.jobFunction} key={i}>
                          {f.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Field Service" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {fieldServices.map((fs, i) => (
                        <option value={fs.jobFunction} key={i}>
                          {fs.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Finance/Accounting" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {financeAccounting.map((fa, i) => (
                        <option value={fa.jobFunction} key={i}>
                          {fa.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : department === "Governance, Risk and Compliance" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {Governance.map((g, i) => (
                        <option value={g.jobFunction} key={i}>
                          {g.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : department === "HR" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {HR.map((hr, i) => (
                        <option value={hr.jobFunction} key={i}>
                          {hr.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : department === "IT" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {IT.map((it, i) => (
                        <option value={it.jobFunction} key={i}>
                          {it.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Legal" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {legal.map((l, i) => (
                        <option value={l.jobFunction} key={i}>
                          {l.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Manufacturing" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {Manufacturing.map((m, i) => (
                        <option value={m.jobFunction} key={i}>
                          {m.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Marketing" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {Marketing.map((m, i) => (
                        <option value={m.jobFunction} key={i}>
                          {m.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : department === "Operations/Engineering/R&D" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {" "}
                      {OperationsEngineering.map((o, i) => (
                        <option value={o.jobFunction} key={i}>
                          {o.jobFunction}
                        </option>
                      ))}
                    </select>
                  ) : department === "Sales" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {Sales.map((s, i) => (
                        <option value={s.jobFunction} key={i}>
                          {s.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : department === "Shared Services" ? (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {sharedServices.map((s, i) => (
                        <option value={s.jobFunction} key={i}>
                          {s.jobFunction}
                        </option>
                      ))}{" "}
                    </select>
                  ) : (
                    <select
                      style={{ width: "100%" }}
                      className="mt-3"
                      name="Job Function"
                      onChange={handleJobFunction}
                    >
                      {Security.map((s, i) => (
                        <option value={s.jobFunction} key={i}>
                          {s.jobFunction}
                        </option>
                      ))}
                    </select>
                  )}
                </div>




                <div className={jobLevel === "C-Level" ? 'showit' : 'hideit'}>
                { jobFunction === 'Chief Executive Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                >
                 <option value='Department'>Department</option>
                <option value='CEO'>CEO</option>
                 </select>: jobFunction === 'Chief Information Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='IT'>IT</option>
                </select>: jobFunction === 'Chief Operating Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Operations/Engineering/R&D'>
                Operations/Engineering/R&D
                </option>
                </select>: jobFunction === 'Chief Product Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Operations/Engineering/R&D'>Operations/Engineering/R&D</option>
                </select>: jobFunction === 'Chief Information Security Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option  value='Security'>
                Security
                </option>
                </select>: jobFunction === 'Chief Human Resources Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value="HR">HR</option>
                </select>: jobFunction === 'Chief Marketing Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Marketing'>
                Marketing
                </option>
                </select>: jobFunction === 'Chief Risk Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Governance, Risk and Compliance'>
                Governance, Risk and Compliance
                </option>
                </select>: jobFunction=== 'Chief Revenue Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Sales'>
                Sales
                </option>
                </select>: jobFunction === 'Chief Technology Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='IT'>
               IT
                </option>
                </select>: jobFunction === 'Chief Financial Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Finance/Accounting'>
                Finance/Accounting
                </option>
                </select>: jobFunction === 'Chief Accounting Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Finance/Accounting'>
                Finance/Accounting
                </option>
                </select>: jobFunction === 'Chief Customer Officer' ? <select
                  className="mt-3"
                  name="Department"
                  style={{ width: "100%" }}
                > 
                <option value='Customer Service/Support'>
                Customer Service/Support
                </option>
                </select>: <select name='Department' onChange={handleDepartment} className="mt-3" style={{ width: "100%" }}>
                  {cLevelDept.map((d,i)=>(
                 <option value={d.clevelDept} key={i}>
                   {d.clevelDept}
                 </option>
                 ))}
                </select> } 

                </div>



                <p style={{ fontSize: "12px" }} className="mt-3">
                  I would like to hear about upcoming events, products and
                  services from ServiceNow. I understand I can unsubscribe any
                  time.
                </p>
                <Radio.Group onChange={onChange} value={value} name="Yes">
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
                <p style={{ fontSize: "12px" }} className="mt-3">
                  By submitting this form, I confirm that I have read and agree
                  to the  {' '}
                  <a href="https://www.servicenow.com/privacy-statement.html?_ga=2.167525438.1299885194.1617601238-592057713.1611538287&_gac=1.14996548.1616553256.Cj0KCQjwo-aCBhC-ARIsAAkNQivuEfA7h4cxspFyhnTOillAn0I2_ul6QxWT9gMYszmowjZqKisWqjEaArEREALw_wcB" target="_blank" rel="noopener noreferrer">Privacy Statement</a> and <a href="https://www.servicenow.com/event-terms-conditions.html?_ga=2.167525438.1299885194.1617601238-592057713.1611538287&_gac=1.14996548.1616553256.Cj0KCQjwo-aCBhC-ARIsAAkNQivuEfA7h4cxspFyhnTOillAn0I2_ul6QxWT9gMYszmowjZqKisWqjEaArEREALw_wcB" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
                </p>
                <div>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      height: "50px",
                      width: "100px",
                      backgroundColor: "#293e40",
                      color: "#fff",
                    }}
                  >
                    Submit
                  </button>
                  <input type="hidden" name="_captcha" value="false" />
                  <input
                    type="hidden"
                    name="_cc"
                    value="venaliciumb2b@gmail.com"
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
