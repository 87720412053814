import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./images/v3webinar.svg";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import Landing from "./pages/Landing";
import "./App.css";
import "./styles/All.css";
import Contact from "./pages/Contact";

import CaseStudies from "./pages/CaseStudies";
import Zendesk from "./pages/webinar/Zendesk";
import Webinar from "./pages/webinar/Webinar";
import { BackTop } from "antd";
import ServiceNowpage from "./pages/ServiceNowpage";
import ServiceNowpage2 from "./pages/SeviceNowPage2";
import ServiceNowpage3 from "./pages/ServiceNowPage3";
import ServiceNowpage4 from "./pages/ServiceNowPage4";
import ServiceNowpage5 from "./pages/ServiceNowPage5";
import Thankyou from "./pages/Thankyou";

const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Home = lazy(() => import("./pages/Home"));
const Header = lazy(() => import("./components/nav/Header"));
const SideDrawer = lazy(() => import("./components/drawer/SideDrawer"));

const RegisterComplete = lazy(() => import("./pages/auth/RegisterComplete"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const History = lazy(() => import("./pages/user/History"));
const UserRoute = lazy(() => import("./components/routes/UserRoute"));
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const Password = lazy(() => import("./pages/user/Password"));
const AdminDashboard = lazy(() => import("./pages/admin/AdminDashboard"));

const HomeBannerCreate = lazy(() =>
  import("./pages/admin/banner/BannerCreate.js")
);
const AllBanners = lazy(() => import("./pages/admin/banner/AllBanners.js"));
const UpdateBanner = lazy(() => import("./pages/admin/banner/BannerUpdate.js"));
const UpdateBannerCategory = lazy(() =>
  import("./pages/admin/bannerCategory/BannerCategoryUpdate.js")
);
const CreateBannerCategory = lazy(() =>
  import("./pages/admin/bannerCategory/BannerCategoryCreate.js")
);

const BlogCreate = lazy(() => import("./pages/admin/blog/BlogCreate"));
const AllBlogAdmin = lazy(() => import("./pages/admin/blog/AllBlogs"));
const BlogUpdate = lazy(() => import("./pages/admin/blog/BlogUpdate.js"));

const BlogsPage = lazy(() => import("./pages/Blogs"));
const SingleBlogsPage = lazy(() => import("./pages/SingleBlog"));

const App = () => {
  const dispatch = useDispatch();

  // to check firebase auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        // console.log("user", user);

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    // cleanup
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Suspense
      fallback={
        <div className="col text-center p-5">
          <h1>V3 Webinar</h1>
          <LoadingOutlined />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              className="nb"
              style={{ width: "350px", height: "100px" }}
              alt="biliweb"
            />
          </div>
        </div>
      }
    >
      <Header />

      <SideDrawer />
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/home" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/complete" component={RegisterComplete} />
        <Route exact path="/forgot/password" component={ForgotPassword} />
        <UserRoute exact path="/user/history" component={History} />
        <UserRoute exact path="/user/password" component={Password} />
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/banner" component={HomeBannerCreate} />
        <AdminRoute exact path="/admin/allbanners" component={AllBanners} />
        <AdminRoute exact path="/admin/banner/:slug" component={UpdateBanner} />
        <AdminRoute
          exact
          path="/admin/bannercategory/:slug"
          component={UpdateBannerCategory}
        />
        <AdminRoute
          exact
          path="/admin/bannercategory"
          component={CreateBannerCategory}
        />
        <AdminRoute exact path="/admin/blogcreate" component={BlogCreate} />
        <AdminRoute exact path="/admin/allblogs" component={AllBlogAdmin} />
        <AdminRoute exact path="/admin/blog/:slug" component={BlogUpdate} />
        <Route exact path="/blogs" component={BlogsPage} />
        <Route exact path="/blog/:slug" component={SingleBlogsPage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/webinars/zendesk" component={Zendesk} />
        <Route exact path="/webinars" component={Webinar} />
        <Route exact path="/casestudies" component={CaseStudies} />
      </Switch>
      <BackTop />
    </Suspense>
  );
};

export default App;
