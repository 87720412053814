import { Button } from "antd";
import React, { useEffect, useState } from "react";

import { getBlogs } from "../functions/blog";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import webinar from "../images/herokudigital.gif";
import Footer from "../components/Footer";

const CaseStudies = (props) => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    loadAllBlogs();
    window.scrollTo(0, 0);
  }, []);

  const loadAllBlogs = () => {
    getBlogs(2).then((res) => {
      setBlogs(res.data);
      console.log(res);
    });
  };

  return (
    <div>
      <div
        className="backGrond"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="gridForWebinar container">
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="impossibleNumber">1</div>
              <div className="blockHeght"></div>
            </div>
            <div>
              <div>
                <h1 style={{ color: "#4d4d4d", fontFamily: "serif" }}>
                  Heroku To Digital
                </h1>
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to="#">
                  <Button size={"large"}>CaseStudy !</Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={webinar} alt="Zendesk" className="img-fluid widthImage" />
          </div>
        </div>
      </div>
      <div className="v3blogmain">
        <div className="container">
          <h3
            style={{
              color: "#fff",
              textAlign: "left",
              fontSize: "50px",
              fontFamily: "serif",
            }}
          >
            Our Recent Blogs
          </h3>
          <div className="threeblogDiv">
            {blogs.slice(2, 5).map((b, i) => (
              <div>
                <div className="v3card2">
                  <img src={b.images[0].url} className="v3image" alt="..." />
                  <div className="v3blogbody">
                    <h5 className="v3blog-tilte">{b.title} </h5>
                    <p className="v3blogexcerpt">
                      {parse(`${b.description.substring(0, 250)}`)}
                    </p>
                    <Link to={`/blog/${b.slug}`} className="read">
                      Read More <span class="spanscript"> </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CaseStudies;
