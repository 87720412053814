import * as firebase from 'firebase';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyD_NkaJJ2h0ay0sI5r4A4mjASusP8h1Fmk",
  authDomain: "amritbiliweb.firebaseapp.com",
  databaseURL: "https://amritbiliweb.firebaseio.com",
  projectId: "amritbiliweb",
  storageBucket: "amritbiliweb.appspot.com",
  messagingSenderId: "97886376900",
  appId: "1:97886376900:web:383a92e66e09d3fc80ca91"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

  //export
  export const auth=firebase.auth();
  export const googleAuthProvider=new firebase.auth.GoogleAuthProvider();