import React from "react";
import axios from "axios";
const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="left-col">
          <img src="/static/images/logo.png" alt="" className="logo" />

          <div className="social-media">
            <a href="https://www.facebook.com/Venalicium-Digital-LLC-108040230680979/?modal=admin_todo_tour">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/VenaliciumL">
              <i className="fab fa-twitter"></i>
            </a>

            <a href="https://www.linkedin.com/company/14510829/admin/">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
          <form
            action="https://formsubmit.co/info@v3finmedia.online"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value="https://v3media.online/wp-content/uploads/2022/04/venaMediakit.pdf"
            />
            <div className="but">
              <label for="name">Name</label>
              <input
                required
                type="text"
                className="txta"
                name="user_name"
                id="name"
                placeholder="Name"
              />
            </div>
            <div className="but">
              <label for="mail">E-mail</label>
              <input
                required
                type="email"
                id="mail"
                className="txta"
                name="email"
                placeholder="Email Address"
              />
            </div>

            <div className="but">
              <label for="msg">Company Name</label>
              <input
                type="text"
                className="txta"
                name="company-name"
                id="msg"
                placeholder="Company Name"
              />
            </div>

            <div className="but">
              <button type="submit" className=" buttonstylebc btn-danger">
                Download Media Kit
              </button>
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_cc" value="venaliciumb2b@gmail.com" />
            </div>
          </form>
        </div>

        <div className="right-col">
          <h1 className="h1footer">THINK OUT OF THE BOX</h1>
          <p className="para">
            Join 350,000+ fellow marketers to receive a summary of the latest
            marketing tips from across the world directly to your inbox once a
            week.
          </p>
          <div className="margin">
            <h1 className="h1footer">WHO WE ARE</h1>
            <p className="para">
              V3 Media is a digital marketing and media company that provides
              brand awareness and lead generation services worldwide
            </p>
            <p className="para">
              The V3 Media team consists of over 300 marketing specialists, data
              science experts, and martech engineers that execute and
              orchestrate over 2,800 campaigns each year.
            </p>
          </div>
        </div>
        <div className="third">
          <h1 className="h1footer">WHAT WE DO</h1>
          <p className="para">
            V3 Media provides performance-based marketing services by
            orchestrating multi-channel campaigns across email, web, mobile,
            voice, social, search, and display.
          </p>
          <p className="para">
            Brands, agencies, platforms and publishers rely on V3 Media for lead
            acquisition at scale. Marketers from large enterprises to small and
            mid-sized companies use V3 Media experts for their marketing
            programs.
          </p>
        </div>
        <div className="fourth">
          <h1 className="h1footer">OUR PUBLICATION SITE</h1>

          <h5 className="h5footer">
            <a
              href="https://v3media.online/"
              style={{ textDecoration: "none", fontWeight: "300" }}
            >
              Media Professionals
            </a>
          </h5>
          <h5 className="h5footer">
            <a
              href="https://v3techmedia.online/"
              style={{ textDecoration: "none", fontWeight: "300" }}
            >
              Technology Professionals
            </a>
          </h5>
          <h5 className="h5footer">
            <a
              href="https://v3finmedia.online/"
              style={{ textDecoration: "none", fontWeight: "300" }}
            >
              Finance Professionals
            </a>
          </h5>

          <h5 className="h5footer">
            <a
              href="https://v3hrmedia.online/"
              style={{ textDecoration: "none", fontWeight: "300" }}
            >
              HR Professionals
            </a>
          </h5>
          <h5 className="h5footer">
            <a
              href="https://v3healthcare.online/"
              style={{ textDecoration: "none", fontWeight: "300" }}
            >
              Health Professionals
            </a>
          </h5>
        </div>
        <div>
          <p className="rights-text">
            {" "}
            &copy; {new Date().getFullYear()} All Rights Reserved.
          </p>
          <p className="rights-text" id="beta">
            Designed | Developed & Maintained by{" "}
            <a href="https://bili.co.in">Bili</a>
          </p>
          <p id="alpha" className="rights-text">
            Designed | Developed & Maintained by <br />{" "}
            <a href="https://bili.co.in">Bili</a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
